import React, { useEffect, useState } from "react"
import Modal from "@material-ui/core/Modal"
import { navigate } from "gatsby"
import AcceptInvite from "../ThemeFile/AcceptInvite"
import apiClient from "../../api/apiClient"
import { invitation } from "../../api/apiRequests"

export default function Home() {
  const [token, setToken] = useState()
  const [showError, setError] = useState(false)
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    if (window.location.search) {
      const token = new URLSearchParams(window.location.search).get("token")
      setToken(token)
    } else {
    }
    setTimeout(() => setVisible(true), 400)
  }, [])

  const handleSubmit = async formStates => {
    try {
      setError(false)
      const res = await apiClient(
        invitation.acceptInvite({
          body: {
            password: formStates.newPassword,
          },
          header: {
            authorization: token,
          },
        })
      )
      if (res?.data?.success) {
        navigate("/venue/login")
      } else {
        setError(res?.data?.message)
      }
    } catch (e) {
      setError(e?.message)
      console.log("err getInvites:", e)
    }
  }

  return (
    <Modal
      onBackdropClick={() => setVisible(false)}
      open={visible}
      onClose={() => setVisible(false)}
    >
      <div
        style={{
          display: "flex",
          width: "100%",
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            width: "40%",
            height: "55vh",
            backgroundColor: "white",
            borderRadius: "10px",
          }}
        >
          <AcceptInvite
            showError={showError}
            handleSubmit={value => handleSubmit(value)}
          />
        </div>
      </div>
    </Modal>
  )
}
